var exports = {};

/*! dom-to-image-more 06-12-2021 */
!function (e) {
  "use strict";

  var c = {
    escape: function (e) {
      return e.replace(/([.*+?^${}()|\[\]\/\\])/g, "\\$1");
    },
    parseExtension: t,
    mimeType: function (e) {
      e = t(e).toLowerCase();
      return function () {
        var e = "application/font-woff",
            t = "image/jpeg";
        return {
          woff: e,
          woff2: e,
          ttf: "application/font-truetype",
          eot: "application/vnd.ms-fontobject",
          png: "image/png",
          jpg: t,
          jpeg: t,
          gif: "image/gif",
          tiff: "image/tiff",
          svg: "image/svg+xml"
        };
      }()[e] || "";
    },
    dataAsUrl: function (e, t) {
      return "data:" + t + ";base64," + e;
    },
    isDataUrl: function (e) {
      return -1 !== e.search(/^(data:)/);
    },
    canvasToBlob: function (t) {
      return t.toBlob ? new Promise(function (e) {
        t.toBlob(e);
      }) : function (i) {
        return new Promise(function (e) {
          for (var t = d(i.toDataURL().split(",")[1]), n = t.length, r = new Uint8Array(n), o = 0; o < n; o++) r[o] = t.charCodeAt(o);

          e(new Blob([r], {
            type: "image/png"
          }));
        });
      }(t);
    },
    resolveUrl: function (e, t) {
      var n = document.implementation.createHTMLDocument(),
          r = n.createElement("base");
      n.head.appendChild(r);
      var o = n.createElement("a");
      return n.body.appendChild(o), r.href = t, o.href = e, o.href;
    },
    getAndEncode: function (a) {
      p.impl.options.cacheBust && (a += (/\?/.test(a) ? "&" : "?") + new Date().getTime());
      return new Promise(function (n) {
        var e,
            t,
            r = p.impl.options.httpTimeout,
            o = new XMLHttpRequest();

        function i(e) {
          console.error(e), n("");
        }

        o.onreadystatechange = function () {
          var t;
          4 === o.readyState && (200 === o.status ? ((t = new FileReader()).onloadend = function () {
            var e = t.result.split(/,/)[1];
            n(e);
          }, t.readAsDataURL(o.response)) : e ? n(e) : i("cannot fetch resource: " + a + ", status: " + o.status));
        }, o.ontimeout = function () {
          e ? n(e) : i("timeout of " + r + "ms occured while fetching resource: " + a);
        }, o.responseType = "blob", o.timeout = r, p.impl.options.useCredentials && (o.withCredentials = !0), o.open("GET", a, !0), o.send(), !p.impl.options.imagePlaceholder || (t = p.impl.options.imagePlaceholder.split(/,/)) && t[1] && (e = t[1]);
      });
    },
    uid: function () {
      var e = 0;
      return function () {
        return "u" + ("0000" + (Math.random() * Math.pow(36, 4) << 0).toString(36)).slice(-4) + e++;
      };
    }(),
    delay: function (n) {
      return function (t) {
        return new Promise(function (e) {
          setTimeout(function () {
            e(t);
          }, n);
        });
      };
    },
    asArray: function (e) {
      for (var t = [], n = e.length, r = 0; r < n; r++) t.push(e[r]);

      return t;
    },
    escapeXhtml: function (e) {
      return e.replace(/%/g, "%25").replace(/#/g, "%23").replace(/\n/g, "%0A");
    },
    makeImage: function (r) {
      return "data:," === r ? Promise.resolve() : new Promise(function (e, t) {
        var n = new Image();
        p.impl.options.useCredentials && (n.crossOrigin = "use-credentials"), n.onload = function () {
          e(n);
        }, n.onerror = t, n.src = r;
      });
    },
    width: function (e) {
      var t = r(e, "border-left-width"),
          n = r(e, "border-right-width");
      return e.scrollWidth + t + n;
    },
    height: function (e) {
      var t = r(e, "border-top-width"),
          n = r(e, "border-bottom-width");
      return e.scrollHeight + t + n;
    }
  };

  function t(e) {
    e = /\.([^\.\/]*?)(\?|$)/g.exec(e);
    return e ? e[1] : "";
  }

  function r(e, t) {
    t = g(e).getPropertyValue(t);
    return parseFloat(t.replace("px", ""));
  }

  var o,
      i = {
    inlineAll: function (t, r, o) {
      return n(t) ? Promise.resolve(t).then(a).then(function (e) {
        var n = Promise.resolve(t);
        return e.forEach(function (t) {
          n = n.then(function (e) {
            return u(e, t, r, o);
          });
        }), n;
      }) : Promise.resolve(t);
    },
    shouldProcess: n,
    impl: {
      readUrls: a,
      inline: u
    }
  };

  function n(e) {
    return -1 !== e.search(o);
  }

  function a(e) {
    for (var t, n = []; null !== (t = o.exec(e));) n.push(t[1]);

    return n.filter(function (e) {
      return !c.isDataUrl(e);
    });
  }

  function u(t, n, r, e) {
    return Promise.resolve(n).then(function (e) {
      return r ? c.resolveUrl(e, r) : e;
    }).then(e || c.getAndEncode).then(function (e) {
      return c.dataAsUrl(e, c.mimeType(n));
    }).then(function (e) {
      return t.replace(new RegExp("(url\\(['\"]?)(" + c.escape(n) + ")(['\"]?\\))", "g"), "$1" + e + "$3");
    });
  }

  var s = {
    resolveAll: function () {
      return l().then(function (e) {
        return Promise.all(e.map(function (e) {
          return e.resolve();
        }));
      }).then(function (e) {
        return e.join("\n");
      });
    },
    impl: {
      readAll: l
    }
  };

  function l() {
    return Promise.resolve(c.asArray(document.styleSheets)).then(function (e) {
      var n = [];
      return e.forEach(function (t) {
        if (Object.getPrototypeOf(t).hasOwnProperty("cssRules")) try {
          c.asArray(t.cssRules || []).forEach(n.push.bind(n));
        } catch (e) {
          console.log("Error while reading CSS rules from " + t.href, e.toString());
        }
      }), n;
    }).then(function (e) {
      return e.filter(function (e) {
        return e.type === CSSRule.FONT_FACE_RULE;
      }).filter(function (e) {
        return i.shouldProcess(e.style.getPropertyValue("src"));
      });
    }).then(function (e) {
      return e.map(t);
    });

    function t(t) {
      return {
        resolve: function () {
          var e = (t.parentStyleSheet || {}).href;
          return i.inlineAll(t.cssText, e);
        },
        src: function () {
          return t.style.getPropertyValue("src");
        }
      };
    }
  }

  var f = {
    inlineAll: function t(e) {
      if (!(e instanceof Element)) return Promise.resolve(e);
      return n(e).then(function () {
        return e instanceof HTMLImageElement ? h(e).inline() : Promise.all(c.asArray(e.childNodes).map(function (e) {
          return t(e);
        }));
      });

      function n(t) {
        var n = t.style.getPropertyValue("background");
        return n ? i.inlineAll(n).then(function (e) {
          t.style.setProperty("background", e, n);
        }).then(function () {
          return t;
        }) : Promise.resolve(t);
      }
    },
    impl: {
      newImage: h
    }
  };

  function h(n) {
    return {
      inline: function (e) {
        return c.isDataUrl(n.src) ? Promise.resolve() : Promise.resolve(n.src).then(e || c.getAndEncode).then(function (e) {
          return c.dataAsUrl(e, c.mimeType(n.src));
        }).then(function (t) {
          return new Promise(function (e) {
            n.onload = e, n.onerror = e, n.src = t;
          });
        });
      }
    };
  }

  var m = {
    imagePlaceholder: void 0,
    cacheBust: !(o = /url\(['"]?([^'"]+?)['"]?\)/g),
    useCredentials: !1,
    httpTimeout: 30000
  },
      p = {
    toSvg: y,
    toPng: function (e, t) {
      return (t = t || {}).raster = !0, v(e, t).then(function (e) {
        return e.toDataURL();
      });
    },
    toJpeg: function (e, t) {
      return (t = t || {}).raster = !0, v(e, t).then(function (e) {
        return e.toDataURL("image/jpeg", t.quality || 1);
      });
    },
    toBlob: function (e, t) {
      return (t = t || {}).raster = !0, v(e, t).then(c.canvasToBlob);
    },
    toPixelData: function (t, e) {
      return (e = e || {}).raster = !0, v(t, e).then(function (e) {
        return e.getContext("2d").getImageData(0, 0, c.width(t), c.height(t)).data;
      });
    },
    toCanvas: function (e, t) {
      return v(e, t || {});
    },
    impl: {
      fontFaces: s,
      images: f,
      util: c,
      inliner: i,
      options: {}
    }
  };
  exports = p;
  const g = e.getComputedStyle || window.getComputedStyle,
        d = e.atob || window.atob;

  function y(r, o) {
    return function (e) {
      void 0 === e.imagePlaceholder ? p.impl.options.imagePlaceholder = m.imagePlaceholder : p.impl.options.imagePlaceholder = e.imagePlaceholder;
      void 0 === e.cacheBust ? p.impl.options.cacheBust = m.cacheBust : p.impl.options.cacheBust = e.cacheBust;
      void 0 === e.useCredentials ? p.impl.options.useCredentials = m.useCredentials : p.impl.options.useCredentials = e.useCredentials;
    }(o = o || {}), Promise.resolve(r).then(function (e) {
      return function r(t, o, a, i) {
        if (!a && o && !o(t)) return Promise.resolve();
        return Promise.resolve(t).then(e).then(function (e) {
          return n(t, e);
        }).then(function (e) {
          return u(t, e, i);
        });

        function e(e) {
          return e instanceof HTMLCanvasElement ? c.makeImage(e.toDataURL()) : "IFRAME" === e.nodeName ? html2canvas(e.contentDocument.body).then(e => e.toDataURL()).then(c.makeImage) : e.cloneNode(!1);
        }

        function n(e, t) {
          e = e.childNodes;
          return 0 === e.length ? Promise.resolve(t) : n(t, c.asArray(e)).then(function () {
            return t;
          });

          function n(t, e) {
            var n = Promise.resolve();
            return e.forEach(function (e) {
              n = n.then(function () {
                return r(e, o, !1, i);
              }).then(function (e) {
                e && t.appendChild(e);
              });
            }), n;
          }
        }

        function u(r, u, t) {
          return u instanceof Element ? Promise.resolve().then(e).then(n).then(o).then(i).then(function () {
            return u;
          }) : u;

          function e() {
            function n(e, t) {
              t.font = e.font, t.fontFamily = e.fontFamily, t.fontFeatureSettings = e.fontFeatureSettings, t.fontKerning = e.fontKerning, t.fontSize = e.fontSize, t.fontStretch = e.fontStretch, t.fontStyle = e.fontStyle, t.fontVariant = e.fontVariant, t.fontVariantCaps = e.fontVariantCaps, t.fontVariantEastAsian = e.fontVariantEastAsian, t.fontVariantLigatures = e.fontVariantLigatures, t.fontVariantNumeric = e.fontVariantNumeric, t.fontVariationSettings = e.fontVariationSettings, t.fontWeight = e.fontWeight;
            }

            function e(e, r) {
              function t(t, n) {
                c.asArray(t).forEach(function (e) {
                  n.setProperty(e, t.getPropertyValue(e), t.getPropertyPriority(e));
                }), a && (["inset-block", "inset-block-start", "inset-block-end"].forEach(e => r.removeProperty(e)), ["left", "right", "top", "bottom"].forEach(e => {
                  r.getPropertyValue(e) && r.setProperty(e, "0px");
                }));
              }

              e.cssText ? (r.cssText = e.cssText, n(e, r)) : t(e, r);
            }

            e(t ? b(r, a) : g(r), u.style);
          }

          function n() {
            function t(o) {
              var i,
                  a = g(r, o),
                  e = a.getPropertyValue("content");

              function t() {
                var e = "." + i + ":" + o,
                    t = (a.cssText ? n : r)();
                return document.createTextNode(e + "{" + t + "}");

                function n() {
                  return a.cssText + " content: " + a.getPropertyValue("content") + ";";
                }

                function r() {
                  return c.asArray(a).map(e).join("; ") + ";";

                  function e(e) {
                    return e + ": " + a.getPropertyValue(e) + (a.getPropertyPriority(e) ? " !important" : "");
                  }
                }
              }

              "" !== e && "none" !== e && (i = c.uid(), (e = u.getAttribute("class")) && u.setAttribute("class", e + " " + i), (e = document.createElement("style")).appendChild(t()), u.appendChild(e));
            }

            [":before", ":after"].forEach(function (e) {
              t(e);
            });
          }

          function o() {
            r instanceof HTMLTextAreaElement && (u.innerHTML = r.value), r instanceof HTMLInputElement && u.setAttribute("value", r.value);
          }

          function i() {
            u instanceof SVGElement && (u.setAttribute("xmlns", "http://www.w3.org/2000/svg"), u instanceof SVGRectElement && ["width", "height"].forEach(function (e) {
              var t = u.getAttribute(e);
              t && u.style.setProperty(e, t);
            }));
          }
        }
      }(e, o.filter, !0, !o.raster);
    }).then(P).then(w).then(function (t) {
      o.bgcolor && (t.style.backgroundColor = o.bgcolor);
      o.width && (t.style.width = o.width + "px");
      o.height && (t.style.height = o.height + "px");
      o.style && Object.keys(o.style).forEach(function (e) {
        t.style[e] = o.style[e];
      });
      var e = null;
      "function" == typeof o.onclone && (e = o.onclone(t));
      return Promise.resolve(e).then(function () {
        return t;
      });
    }).then(function (e) {
      return e = e, t = o.width || c.width(r), n = o.height || c.height(r), Promise.resolve(e).then(function (e) {
        return e.setAttribute("xmlns", "http://www.w3.org/1999/xhtml"), new XMLSerializer().serializeToString(e);
      }).then(c.escapeXhtml).then(function (e) {
        return "<foreignObject x=\"0\" y=\"0\" width=\"100%\" height=\"100%\">" + e + "</foreignObject>";
      }).then(function (e) {
        return "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"" + t + "\" height=\"" + n + "\">" + e + "</svg>";
      }).then(function (e) {
        return "data:image/svg+xml;charset=utf-8," + e;
      });
      var t, n;
    });
  }

  function v(o, i) {
    return y(o, i).then(c.makeImage).then(c.delay(0)).then(function (e) {
      var t = "number" != typeof i.scale ? 1 : i.scale,
          n = function (e, t) {
        var n = document.createElement("canvas");
        n.width = (i.width || c.width(e)) * t, n.height = (i.height || c.height(e)) * t, i.bgcolor && ((t = n.getContext("2d")).fillStyle = i.bgcolor, t.fillRect(0, 0, n.width, n.height));
        return n;
      }(o, t),
          r = n.getContext("2d");

      return r.mozImageSmoothingEnabled = !1, r.msImageSmoothingEnabled = !1, r.imageSmoothingEnabled = !1, e && (r.scale(t, t), r.drawImage(e, 0, 0)), n;
    });
  }

  function P(n) {
    return s.resolveAll().then(function (e) {
      var t = document.createElement("style");
      return n.appendChild(t), t.appendChild(document.createTextNode(e)), n;
    });
  }

  function w(e) {
    return f.inlineAll(e).then(function () {
      return e;
    });
  }

  function b(e, t) {
    var n,
        r = document.createElement(e.tagName).style,
        o = g(e),
        i = e.style;

    for (n of o) {
      var a = o.getPropertyValue(n),
          u = i.getPropertyValue(n);
      i.setProperty(n, t ? "initial" : "unset"), o.getPropertyValue(n) !== a ? r.setProperty(n, a) : r.removeProperty(n), i.setProperty(n, u);
    }

    return r;
  }
}(exports);
export default exports;